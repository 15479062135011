import {
	QueryClient,
	QueryClientProvider,
} from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import React from "react";

export const wrapRootElement = ({ element }) => {
	const queryClient = new QueryClient();

	return (
		<QueryClientProvider client={queryClient}>
			{element}
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	);
};
